
<template>
  <div>
     <user-email-verification v-if="!activeUser.is_verified" />

    <div class="vx-row">
      <div class="vx-col w-1/2 md:w-1/2 lg:w-1/3 mb-base">
        <statistics-card-line
          v-if="pendingOrders.analyticsData"
          icon="CoffeeIcon"
          :hideChart="true"
          :statistic="pendingOrders.analyticsData.total | k_formatter"
          statisticTitle="Pending Orders"
          color='success'
          type='area' />
      </div>

      <div class="vx-col w-1/2 md:w-1/2 lg:w-1/3 mb-base">
        <statistics-card-line
          v-if="deliveredOrders.analyticsData"
          icon="ShoppingCartIcon"
          :hideChart="true"
          :statistic="deliveredOrders.analyticsData.total"
          statisticTitle="Delivered Orders"
          color='danger'
          type='area' />
      </div>
      <div class="vx-col w-1/2 md:w-1/2 lg:w-1/3 mb-base">
        <statistics-card-line
          v-if="completedOrders.analyticsData"
          icon="AwardIcon"
          :hideChart="true"
          :statistic="completedOrders.analyticsData.total | k_formatter"
          statisticTitle="Completed Orders"
          color='warning'
          type='area' />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <order-list />
      </div>
    </div>
  </div>
</template>

<script>

import OrderList from './orders/OrderList.vue'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import UserEmailVerification    from "./account/UserEmailVerification.vue"

export default{
    components: {
      StatisticsCardLine,
      UserEmailVerification,
      OrderList,
    },
    data() {
      return {
        refundedOrders: {
          analyticsData: {
            total: 0
          }
        },
        pendingOrders: {
          analyticsData: {
            total: 0
          }
        },
        deliveredOrders: {
          analyticsData: {
            total: 0
          }
        },
        completedOrders:  {
          analyticsData: {
            total: 0
          }
        }
      }
    },
    computed:{
        activeUser(){
            return this.$store.state.AppActiveUser
        }
    },
    created() {
     this.$http.get(`/analytics`)
      .then((response) => {
        if(response.data.success){
          var result = response.data.result
          this.refundedOrders.analyticsData.total = result.orders.refunded
          this.pendingOrders.analyticsData.total = result.orders.pending
          this.deliveredOrders.analyticsData.total = result.orders.delivered
          this.completedOrders.analyticsData.total = result.orders.completed
        }else{
          this.alertError(response.data.error)
        }
      }).catch((error) => { console.log(error) })
    }
}
</script>

<style lang="scss">
.chat-card-log {
    height: 400px;

    .chat-sent-msg {
        background-color: #f2f4f7 !important;
    }
}

.vx-card.system-user-chart .vx-card__body{
  padding-bottom:5px;
}
</style>
